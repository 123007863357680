<template>
  <div class="orgmenag-container">
    <div class="table-container">
      <el-row class="title-container">
        <el-col :span="8">
          <div class="table-title"> {{ $t('accountManagement.sidebarOrganization') }} </div>
        </el-col>
        <el-col :span="16" class="title-right">
          <el-form
            :inline="true"  
            :model="filterOptions"
            label-position="right"
            label-width="60px"
            class="demo-form-inline"
          >
            <el-form-item
              prop="keyword"
            >
              <el-input
                v-model="filterOptions.keyword"
                :placeholder="$t('Form.placeholderKeyword')"
                suffix-icon="el-icon-search"
                clearable
                @input="onChangeFilter"
              />
            </el-form-item>
          </el-form>
          <el-button 
            v-if="isAdmin"
            type="success"
            @click="handleAddOrg"
          >
            + {{ $t('accountManagement.organ.add') }}
          </el-button>
        </el-col>
      </el-row>
      <el-table
        class="desk-pad-view"
        v-loading="loading"
        :data="tableData"
        stripe
      >
        <el-table-column
          v-for="(item, index) in tableFields"
          :key="index"
          :prop="item.prop"
          :align="item.align"
          :width="item.width"
          :sortable="item.sortable"
          :label="item.label"
        />
        <el-table-column
          prop="description"
          :label="$t('accountManagement.description')"
          width="230px"
        >
          <template slot-scope="scope">
            <span class="description-row">{{ scope.row.description }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="disabled"
          width="100px"
          align="center"
          :label="$t('accountManagement.state')"
        >
          <template slot-scope="scope">
            <template v-if="scope.row.disabled">
              <el-tag :type="'danger'">
                {{ $t('accountManagement.inactive') }}
              </el-tag>
            </template>
            <template v-else>
              <el-tag :type="'success'">
                {{ $t('accountManagement.active') }} 
              </el-tag>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          v-if="isAdmin"
          :label="$t('accountManagement.edit')"
          width="80px"
        >
          <template slot-scope="scope">
            <el-button
              type="success"
              icon="el-icon-edit"
              circle
              plain
              size="mini"
              @click="handleEditIcon(scope.row)"
            />
          </template>
        </el-table-column>
        <template slot="empty">
          <el-empty :description="$t('general.NoData')" />
        </template>
      </el-table>
      <el-table
        class="phone-view"
        v-loading="loading"
        :data="tableData"
        stripe
      >
        <el-table-column
          prop="name"
          :label="$t(`accountManagement.name`)"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.display }}</span><br>
            <span class="role-phone-style">{{ scope.row.owner.name }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="disabled"
          width="50px"
          align="center"
          :label="$t('accountManagement.state')"
        >
          <template slot-scope="scope">
            <template v-if="scope.row.disabled">
              <el-tag :type="'danger'">
                {{ $t('accountManagement.inactive') }}
              </el-tag>
            </template>
            <template v-else>
              <el-tag :type="'success'">
                {{ $t('accountManagement.active') }} 
              </el-tag>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          v-if="isAdmin"
          :label="$t('accountManagement.edit')"
          width="60px"
          align="center"
        >
          <template slot-scope="scope">
            <el-button
              type="success"
              icon="el-icon-edit"
              circle
              plain
              size="mini"
              @click="handleEditIcon(scope.row)"
            />
          </template>
        </el-table-column>
        <template slot="empty">
          <el-empty :description="$t('general.NoData')" />
        </template>
      </el-table>
      <!-- pagination -->
      <div class="pagination-container">
        <el-pagination
          :background="true"
          :current-page="currentPage"
          layout="total, sizes, prev, pager, next"
          :page-sizes="[10, 20, 30, 40, 50]"
          :page-size="pageLimit"
          :total="totalPage"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <AddOrg
      v-if="dialogVisibleAdd" 
      :dialog-visible-add="dialogVisibleAdd"
      @closeDialog="emitCloseDialog"
    />
    <EditOrg
      v-if="dialogVisible" 
      :dialog-visible="dialogVisible"
      :org-info="orgInfo"
      @closeDialog="emitCloseDialog"
    />
  </div>
</template>

<script>
import AddOrg from './AddOrg.vue'
import EditOrg from './EditOrg.vue'
import { MemberRole } from '@/Mixins'

import { getOrganization } from '@/api/organization'
const params = () => ({
  page: 1,
  page_limit: 20
})

export default {
  name: 'OrgManagement',
  components: { AddOrg, EditOrg },
  mixins: [MemberRole],
  data () {
    return {
      tableData: [],
      tableFields: [
        {
          label: this.$t('accountManagement.org'),
          prop: 'display'
        },
        {
          label: this.$t('accountManagement.orgId'),
          prop: 'name',
          width: '150px'
        },
        {
          label: this.$t('Organization.Organization_Employer'),
          prop: 'owner.name'
        }
      ],
      filterOptions: {
        keyword: ''
      },
      dialogVisible: false,
      dialogVisibleAdd: false,
      loading: true,
      params: params(),
      currentPage: 1,
      pageLimit: 20,
      totalPage: 0
    }
  },
  computed: {
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData () {
      this.fetchData()
    },
    async fetchData() {
      this.loading = true
      try {
        const res = await getOrganization(this.params)
        const data = res.data.organization_list
        this.totalPage = res.data.page.total
        this.tableData = data.map((item) => {
          const newObj = {
            ...item
          }
          return newObj
        })
      } catch (error) {
        console.log('getOrganization-error: ', error)
      }
      this.loading = false
    },
    async onChangeFilter() {
      this.params = params()
      this.params.search = this.filterOptions.keyword
      this.fetchData()
    },
    handleEditIcon(row) {
      this.dialogVisible = true
      this.orgInfo = row
    },
    handleAddOrg() {
      this.dialogVisibleAdd = true
    },
    emitCloseDialog() {
      this.dialogVisibleAdd = false
      this.dialogVisible = false
      this.orgInfo = {}
      this.loadData()
    },
    handleSizeChange(val) {
      this.params.page_limit = val
      this.loadData()
    },
    handleCurrentChange(val) {
      this.params.page = val
      this.loadData()
    }
  }
}

</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.orgmenag-container{
  width: 100%;
  .demo-form-inline{
    display: contents;
  }
  .el-divider{
    margin: 24px 0 0;
  }
  @media (min-width: 577px) { 
    .desk-pad-view{
      display: block;
    }
    .phone-view{
      display: none;
    } 
  }
  @media (max-width: 576px) { 
    .desk-pad-view{
      display: none;
    }
    .phone-view{
      display: block;
      .role-phone-style{
        font-weight: bold;
        color: $link;
      }
    }
  }
  .table-container{
    padding: 0 20px;
    .description-row{
      overflow:hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .title-container{
      margin: 10px 0;
      .table-title{
        font-size: 20px;
        line-height: 2;
        text-align: left;
      }

      .title-right{
        text-align: end;
        .el-button {
          margin-bottom: 5px;
        }
      }
    }
  }
  
}
/* pagination */
.pagination-container {
  @include justifyCenter;
  .el-pagination {
    margin-top: 10px;
    background-color: #f3f2f1;
  }
}
</style>
