<template>
  <div class="edit-org">
    <el-dialog 
      :visible="dialogVisible"
      custom-class="edit-org-dialog"
      @close="handleClose"
    >
      <div slot="title">
        {{ $t('accountManagement.organ.edit') }}
        <el-divider />
      </div>
      <el-form
        ref="editOrgForm"
        :model="editOrgForm"
        label-position="top"
      >
        <el-form-item
          v-for="(item, index) in editFormOrg"
          :key="index"
          :label="$t(`accountManagement.${item.label}`)"
          :prop="item.prop"
        >
          <el-input
            v-model="editOrgForm[item.prop]"
            :disabled="item.disabled"
            :type="item.type"
          />
        </el-form-item>
        <div class="form-divider">
          <span> {{ $t('accountManagement.organ.editOwner') }}</span>
          <el-divider />
        </div>
        
        <el-form-item :label="$t(`Organization.Organization_Employer`)" prop="role">
          <el-select v-model="editOrgForm.owner_id" filterable>
            <el-option 
              v-for="item in memberList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t(`accountManagement.OrgState`)" :label-width="formLabelWidth" class="radio-style">
          <el-radio-group v-model="editOrgForm.disabled">
            <el-radio label="false"> {{ $t('accountManagement.active') }}</el-radio>
            <el-radio label="true">{{ $t('accountManagement.inactive') }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="delete-btn" type="danger" plain @click="handleDelete">{{ $t('general.Delete') }}</el-button>
        <el-button type="info" plain @click="handleClose">{{ $t('general.Cancel') }}</el-button>
        <el-button type="success" plain @click="submitForm('editOrgForm')">{{ $t('general.Save') }}</el-button>
      </div>
    </el-dialog>
    <el-dialog
      v-loading="loading"
      :title="$t('general.Warning')"
      :visible.sync="deletedialogVisible"
      width="40%"
    > 
      <div class="dialog-desc">{{ $t('accountManagement.isDeleteOrg') }}</div>
      <span class="dialog-notice">{{ $t('accountManagement.isDeleteOrgDESC') }}</span>
      <h3>{{ deletedName }}</h3>
      <el-table
        :data="memberList"
        stripe
        style="width: 80%"
      >
        <el-table-column
          type="index"
        />
        <el-table-column
          prop="name"
          label="姓名"
        />
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" plain @click="fetchDelete">{{ $t('general.Delete') }}</el-button>
        <el-button type="info" plain @click="deletedialogVisible = false">{{ $t('general.Cancel') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { updateOrganization, deleteOrganization } from '@/api/organization'
import { MemberRole, SuccessMessage } from '@/Mixins'

export default {
  name: 'EditOrg',
  mixins: [MemberRole, SuccessMessage],
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    orgInfo: {
      type: Object,
      default: () => []
    }
  },
  data () {
    return {
      editOrgForm: {
        display: '',
        taxId: '', // 統編
        owner_id: null,
        description: null,
        disabled: 'false'
      },
      editFormOrg: [
        {
          label: 'organization',
          prop: 'display'
        },
        {
          label: 'orgId',
          prop: 'taxId',
          disabled: true
        },
        {
          label: 'description',
          prop: 'description',
          type: 'textarea'
        }
      ],
      formLabelWidth: '120px',
      memberList: [],
      loading: false,

      // delete
      deletedialogVisible: false,
      deletedId: '',
      deletedName: '',

      memberId: 0,
      org_id: 0
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData () {
      this.setEditFormData()
    },
    async fetchEditOrg() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)'
      })

      let newObj = {}
      newObj = {
        display: this.editOrgForm.display,
        owner_id: this.editOrgForm.owner_id,
        description: this.editOrgForm.description,
        disabled: this.editOrgForm.disabled !== 'false' 
      }
      try {
        await updateOrganization(this.org_id, newObj)
        this.showSuccessMessage(this.$t('Notify.Updated'))
        this.handleClose()
      } catch (error) {
        console.error(error)
      }
      loading.close()
    },
    async fetchDelete() {
      this.loading = true
      try {
        await deleteOrganization(this.deletedId)
        this.showSuccessMessage(this.$t('Notify.Deleted'))
      } catch (error) {
        console.error(error)
      }
      this.deletedId = ''
      this.deletedName = ''
      this.deletedialogVisible = false
      this.loading = false
      this.handleClose()
    },
    handleDelete() {
      this.deletedialogVisible = true
      this.deletedId = this.org_id
      this.deletedName = this.editOrgForm.display
      this.memberCount = this.memberList.length
    },
    setEditFormData() {
      const state = this.orgInfo.disabled 

      this.editOrgForm.display = this.orgInfo.display
      this.editOrgForm.taxId = this.orgInfo.name
      this.editOrgForm.owner_id = this.orgInfo.owner.id
      this.editOrgForm.description = this.orgInfo.description
      this.editOrgForm.disabled = state ? 'true' : 'false'
      this.org_id = this.orgInfo.id
      this.memberList = this.orgInfo.members
    },
    submitForm(editOrgForm) {
      this.$refs[editOrgForm].validate((valid) => {
        if (valid) {
          this.fetchEditOrg()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleClose() {
      this.$emit('closeDialog')
    }
  }
}

</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
::v-deep.el-form--label-top .el-form-item__label{
  padding-bottom: 0 !important;
}
::v-deep .edit-org-dialog .el-dialog__body{
  padding-top: 0!important;
}
::v-deep.el-divider--horizontal {
  margin-bottom: 0;
}
.edit-org{
  ::v-deep .edit-org-dialog{
    @media (max-width: 576px) {
      width: 90% ;
      min-width: 280px;
    }
    .el-divider{
      margin-bottom: 0;
    }
    
    .from-left{
      text-align: left;
    }
    .pwd-container{
      position: relative;
      .show-pwd {
        position: absolute;
        right: 10px;
        top: 0px;
        font-size: 16px;
        cursor: pointer;
        user-select: none;
      }
    }
    
    .form-divider{
      color: $second;
      display: flex;
      span{
        width: 150px;
        padding: 0 15px;
      }
      .el-divider{
        margin: 10px 0 30px 0;
        background-color: $second;
      }
    }
    .dialog-footer{
      position: relative;
      .delete-btn{
        position: absolute;
        left: 0;
      }
    }
  }  
  
  .el-select{
    width: -webkit-fill-available;
    }
    .radio-style{
      text-align: left;
    }
}
</style>
