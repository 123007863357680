<template>
  <div class="add-org">
    <el-dialog 
      :visible="dialogVisibleAdd"
      :close-on-click-modal="false"
      custom-class="add-org-dialog"
      @close="handleClose"
    >
      <div slot="title">
        {{ $t('accountManagement.organ.add') }}
        <el-divider />
      </div>
      <el-form
        ref="editOrgForm"
        :model="editOrgForm"
        :rules="rules"
        label-position="top"
      >
        <el-form-item
          v-for="(item, index) in editFormOrg"
          :key="index"
          :label="$t(`accountManagement.${item.label}`)"
          :prop="item.prop"
        >
          <el-input
            v-model="editOrgForm[item.prop]"
            autocomplete="off"
            :type="item.type"
          />
        </el-form-item>
        <div class="form-divider">
          <span> {{ $t('accountManagement.organ.addOwner') }}</span>
          <el-divider />
        </div>
        <div class="login-container">
          <el-form-item :label="$t(`accountManagement.login`)" prop="login">
            <el-input v-model="editOrgForm.login" autocomplete="off" />
          </el-form-item>
          <CheckAccountDuplicateButton 
            :login-value="editOrgForm.login"
            @isCheckDuplicate="handleIsCheckLoginDuplicate"
          />
        </div>
        <el-form-item :label="$t(`accountManagement.name`)" prop="name">
          <el-input v-model="editOrgForm.name" autocomplete="off" />
        </el-form-item>
        <el-form-item 
          :label="$t(`accountManagement.password`)"
          prop="password"
          class="pwd-container"
        >
          <el-input 
            ref="password"
            v-model="editOrgForm.password" 
            autocomplete="off"
            :type="passwordType"
          />
          <span
            class="show-pwd"
            @click="showPwd"
          >
            <em :class="passwordType === 'password' ? 'ri-eye-close-line' : 'ri-eye-fill'" />
          </span>
        </el-form-item>
        <el-form-item :label="$t(`accountManagement.email`)" prop="email">
          <el-input v-model="editOrgForm.email" autocomplete="off" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="info" plain @click="handleClose">{{ $t('general.Cancel') }}</el-button>
        <el-button type="success" plain @click="submitForm('editOrgForm')">{{ $t('general.Save') }}</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :title="$t(`general.Notify`)"
      :visible.sync="tokenCreated"
      width="30%"
      class="token-dialog"
    >
      <el-row class="savetoken-token">
        <el-col :span="22" class="token-text">
          {{ token }}
        </el-col>
        <el-col :span="2" class="button-container">
          <el-button class="btn-copy" type="info" plain circle @click="copy">
            <i class="el-icon-document-copy" />
          </el-button>
        </el-col> 
      </el-row>
      <div class="savetoken-notice">*{{ $t('accessToken.saveWarning') }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">{{ $t('general.Close') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import CheckAccountDuplicateButton from '@/components/Button/CheckAccountDuplicateButton.vue'
import { postOrganization } from '@/api/organization'
import { MemberRole, Versions, SuccessMessage } from '@/Mixins'

export default {
  name: 'AddOrg',
  components: { CheckAccountDuplicateButton },
  mixins: [MemberRole, Versions, SuccessMessage],
  props: {
    dialogVisibleAdd: {
      type: Boolean,
      default: false
    }
  },
  data () {
    const validatePassword = (rule, value, callback) => {
      if (value.length > 0 && value.length < 8) {
        callback(new Error(this.$t('RuleMsg.PasswordLimit')))
      } else {
        callback()
      }
    }
    return {
      editOrgForm: {
        display: '',
        taxId: '',
        name: '',
        owner_id: null,
        description: '',
        login: '',
        email: '',
        password: '',
        orgCity: ''
      },
      editFormOrg: [
        {
          label: 'organization',
          prop: 'display'
        },
        {
          label: 'orgId',
          prop: 'taxId'
        },
        {
          label: 'orgCity',
          prop: 'orgCity'
        },
        {
          label: 'description',
          prop: 'description',
          type: 'textarea'
        }
      ],
      rules: {
        display: [
          { 
            required: true,
            message: this.$t('accountManagement.validOrgName'),
            trigger: 'blur'
          }
        ],
        taxId: [
          { 
            required: true,
            message: this.$t('accountManagement.validOrgID'),
            trigger: 'blur'
          }
        ],
        orgCity: [
          { 
            required: true,
            message: this.$t('accountManagement.orgCity'),
            trigger: 'blur'
          }
        ],
        login: [
          { 
            required: true,
            message: this.$t('accountManagement.validLogin'),
            trigger: 'blur'
          }
        ],
        password: [
          { 
            required: true,
            pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])^[\w!@#$%^&*()+|{}\[\]`~\-'";:/?.\\>,<]{8,20}$/,
            message: this.$t('RuleMsg.Invalid') + this.$t('RuleMsg.Password'),
            trigger: 'blur'
          },
          { validator: validatePassword, trigger: 'blur' }
        ],
        name: [
          { 
            required: true,
            message: this.$t('accountManagement.validOrgOwnerName'),
            trigger: 'blur'
          }
        ],
        email: [
          { 
            required: true,
            message: this.$t('accountManagement.validemail'),
            trigger: 'blur'
          }
        ]
      },
      isCheckLoginDuplicate: false,
      formLabelWidth: '120px',
      tokenCreated: false,
      token: '',
      passwordType: 'password'
    }
  },
  methods: {
    async fetchEditOrg() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)'
      })

      let newObj = {}
      let newObjMember = {}
      newObjMember = {
        login: this.editOrgForm.login,
        name: this.editOrgForm.name,
        password: this.editOrgForm.password,
        email: this.editOrgForm.email,
        terms_version: this.version_member_infoagree_agree
      }

      newObj = {
        display: this.editOrgForm.display,
        name: this.editOrgForm.taxId,
        org_city: this.editOrgForm.orgCity,
        description: this.editOrgForm.description,
        owner: newObjMember
      }
      try {
        const res = await postOrganization(newObj)
        this.token = res.data.token
        this.tokenCreated = true
        this.showSuccessMessage(this.$t('Notify.Added'))
      } catch (error) {
        console.error('postOrganization-error: ', error)
      }
      loading.close()
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    handleIsCheckLoginDuplicate(isCheck) {
      this.isCheckLoginDuplicate = isCheck
    },
    submitForm(editOrgForm) {
      this.$refs[editOrgForm].validate((valid) => {
        if (valid) {
          if (this.isCheckLoginDuplicate) {
            this.fetchEditOrg()
          } else {
            this.$message({
              title: this.$t('general.Error'),
              message: this.$t('Notify.CheckLoginDuplicate'),
              type: 'error'
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleClose() {
      this.tokenCreated = false
      this.$emit('closeDialog')
    },
    copy() {
      const copyData = this.token

      if (navigator.clipboard) {
        navigator.clipboard.writeText(copyData)
      } else {
        const textArea = document.createElement('textarea')
        textArea.value = copyData
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()

        return new Promise((res, rej) => {
          document.execCommand('copy') ? res() : rej
          textArea.remove()
        })
      }
      this.showSuccessMessage(this.$t('general.Copy'))
    }
  }
}

</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
::v-deep.el-form--label-top .el-form-item__label{
  padding-bottom: 0 !important;
}
::v-deep .add-org-dialog .el-dialog__body{
  padding-top: 0!important;
}
::v-deep.el-divider--horizontal {
  margin-bottom: 0;
}
.add-org{
  .token-dialog{
    text-align: left;
    .savetoken-container{
      top: 0;
      padding: 20px 30px;
      text-align: left;
      font-size: 14px;
      background-color: $bg_header;
      border-radius: 10px;
      margin-bottom: 10px;
      font-weight: 600;
      color: $text;
      .savetoken-token{
        background-color: $bg_site;
        border-radius: 10px;
        padding-left: 10px;
        margin: 10px -5px;
        box-shadow: 0 0 10px rgba(65, 61, 61, 0.6);
        .token-text{
          line-height: 40px;
        }
        .button-container{
          text-align: right;
        }
        .el-button{
          background-color: transparent;
          border: none;
        }

        .el-button:hover{
          color: rgba(65, 61, 61);
        }
      }

      .savetoken-notice{
        color: $danger;
        font-size: 13px;
      }
    }
  }

  ::v-deep .add-org-dialog{
    @media (max-width: 576px) {
      width: 90% ;
      min-width: 280px;
    }
    .el-divider{
      margin-bottom: 0;
    }
    
    .from-left{
      text-align: left;
    }
    .login-container{
      position: relative;
    }
    .pwd-container{
      position: relative;
      .show-pwd {
        position: absolute;
        right: 10px;
        top: 0px;
        font-size: 16px;
        cursor: pointer;
        user-select: none;
      }
    }
    
    .form-divider{
      color: $second;
      display: flex;
      span{
        width: 150px;
        padding: 0 15px;
      }
      .el-divider{
        margin: 10px 0 30px 0;
        background-color: $second;
      }
    }
    
  }  
  
  .el-select{
    width: -webkit-fill-available;
    }
    .radio-style{
      text-align: left;
    }
}
</style>
